.wrapper {

}

.s {
  h2, .h2 {
    font-size: 50px;
    font-weight: 600;
    font-style: normal;
    letter-spacing: 2.5px;
    &.font-s30 {
      font-size: 30px;
      font-weight: 600;
      font-style: normal;
      letter-spacing: 1.5px;
    }
  }
}
.s-bg-img {
  position: relative;
  .img-half {
    position: absolute;
    top: 0;
    height: 100%;
    width: 50%;
    z-index: 0;
    overflow: hidden;
    &-left {
      left: 0;
    }
    &-right {
      right: 0;
    }
  }
}
.s-top {
  position: relative;
  z-index: 0;
  .container {
    position: relative;
    z-index: 2;
  }
  .ring {
    position: absolute;
    z-index: 0;
    width: 720px;
    height: 720px;
    border-radius: 50%;
    overflow: hidden;
    left: 50%;
    transform: translateX(-50%);
    bottom: -361px;
    background: url('../img/ring.png') 0 0 no-repeat;
    opacity: 0.42;
  }
}

.top__lp-bank {
  background: url('../img/top-bg-lp-2.jpg') 0 0 no-repeat;
  background-size: cover;
  min-height: 498px;

}
.s-top-lp-banken {
  .ring {
    top: 138px;
    background-image: url('../img/ring-top-2.png');
  }
}
.container-lp-banken-bottom {
  padding-top: 170px;
}

.btn-after-top {
  position: relative;
  z-index: 2;
}
.s-steps {
  position: relative;
  z-index: 1;
}
.s-advantages {
  border-top: 1px solid #ccc;
}
.s-testimonials {
  position: relative;
  .slider-outer {
    position: relative;
  }
}
.s-urs {
  min-height: 794px;
  .container {
    position: relative;
    z-index: 2;
  }
}

.page-title {
  font-size: 50px;
  font-weight: 600;
  font-style: normal;
  letter-spacing: 2.5px;
}
.s-price-desc {
  h2, .h2 {
    &.font-s30 {
      letter-spacing: 1.5px;
      line-height: 40px;
    }
  }
}
.s-features-top,
.s-top_branchen {
  min-height: 772px;
  background-position: 0 0;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center 0;
  .font-s30 {
    font-size: 30px;
    font-weight: 400;
    font-style: normal;
    letter-spacing: 1.5px;
    line-height: 40px;
  }
}
.s-top-lp-banken,
.s-top_branchen,
.s-top_branchen-mob {
  .title-before {
    strong {
      display: inline-block;
      position: relative;
      padding-bottom: 0;
      border-bottom: 1px solid #2bcaf5;
    }
  }
}

.s-top_branchen {
  &.bg-0 {
    background-image: url('../img/br-bg-0.jpg');
  }
  &.bg-1 {
    background-image: url('../img/br-bg-1.jpg');
  }
  &.bg-2 {
    background-image: url('../img/br-bg-2.jpg');
  }
  h1, .h1 {
    font-size: 50px;
    font-weight: 700;
    letter-spacing: 2.5px;
  }
}
.s-advantages-blue {
  background-color: #0073b6;
  position: relative;
  z-index: 0;
  + div {
    position: relative;
    z-index: 1;
  }
  .container {
    position: relative;
    z-index: 1;
  }
  .ring {
    width: 720px;
    height: 720px;
    position: absolute;
    z-index: 0;
    left: 50%;
    margin-left: -360px;
    top: 100%;
    margin-top: -360px;
    background-image: url('../img/ring.png');
    opacity: 0.4;
  }
  h2 {
    color: #fff;
  }
}

.s-features-top {
  background-position: 50% 0;
  &.bg-0 {
    background-image: url('../img/ft-bg-0.jpg');
  }

  &.bg-1 {
    background-image: url('../img/ft-bg-1.jpg');
  }

  &.bg-2 {
    background-image: url('../img/ft-bg-2.jpg');
  }
}

.link-play-video {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%,-50%);
  z-index: 10;
}