.slider-0-arrows {
  position: absolute;
  z-index: 0;
  width: 100%;
  height: 100%;

  >.container {
    position: relative;
    z-index: 1;
  }

  .slider-0-arrow {
    position: absolute;
    // top: 50%;
    // transform: translateY(-50%);
    top: 90px;
    width: 47px;
    height: 89px;
    cursor: pointer;

    &-prev {
      left: 40px;
      background: url('../img/arr-prev.svg');
    }

    &-next {
      right: 40px;
      background: url('../img/arr-next.svg');
    }
  }
}

.carousel-rs {
  height: 794px;
  width: 50%;
  position: absolute;
  z-index: 0;
  right: 0;
  top: 0;
  .item {
    position: relative;
    height: 794px;
    > div {
      width: 100%;
      right: 0;
    }
  }
}

.slider-rs-arrows {
  position: absolute;
  width: 100%;
  top: 50%;
  left: 0;
  z-index: 10;
  .slider-rs-arrow {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    width: 47px;
    height: 89px;
    cursor: pointer;

    &-prev {
      left: 40px;
      background: url('../img/arr-prev.svg');
    }

    &-next {
      right: 40px;
      background: url('../img/arr-next.svg');
    }
  }
}
.carousel-references {
  position: relative;
  .slick-arrow {
    position: absolute;
    top: 105px;
    z-index: 10;
    font-size: 0;
    line-height: 0;
    width: 41px;
    height: 41px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.16);
    background-color: #ffffff;
    border: none;
    border-radius: 50%;
    &:before {
      content: "";
      display: block;
      width: 14px;
      height: 24px;
      position: absolute;
      top: 50%;
      left: 50%;
    }
    &.slick-prev {
      left: 0;
      &:before {
        transform: translate(-50%,-50%);
        background: url('../img/arr-prev-b.svg') 0 0 no-repeat;
      }
    }
    &.slick-next {
      right: 0;
      &:before {
        transform: translate(-50%,-50%) rotate(180deg);
        background: url('../img/arr-prev-b.svg') 0 0 no-repeat;
      }
    }
  }
}