$font: 'Amiko', sans-serif;

$color-blue: #1e2b57;
$color-blue-light: #0073b6;
$color-blue-light-2: #2bcaf5;
$color-red: #d9000f;
$color-text-blue: #174b78;

$spacer: 0.625rem;
$spacers: ();
$spacers: map-merge((0: 0,
      1: ($spacer * 1),
      2: ($spacer * 2),
      3: $spacer * 3,
      4: ($spacer * 4),
      5: ($spacer * 5),
      6: ($spacer * 6),
      7: ($spacer * 7),
      8: ($spacer * 8),
      9: ($spacer * 9),
      10: ($spacer * 10),
      11: ($spacer * 11),
      12: ($spacer * 12),
      13: ($spacer * 13),
      14: ($spacer * 14),
      16: ($spacer * 16),
      17: ($spacer * 17),
      18: ($spacer * 18),
      19: ($spacer * 19),
      20: ($spacer * 20),
      21: ($spacer * 21),
      22: ($spacer * 22),
      23: ($spacer * 23),
      24: ($spacer * 24),
      25: ($spacer * 25),
      26: ($spacer * 26),
      27: ($spacer * 27),
      28: ($spacer * 28)
    ),
    $spacers
);
$grid-breakpoints: (
    xs: 0,
    sm: 576px,
    md: 768px,
    lg: 992px,
    xl: 1200px
);
$grid-gutter-width: 38px;
$container-max-widths: (
  sm: 100%,
  md: 100%,
  lg: 960px,
  xl: 1238px
);
$modal-xl: 1100px;