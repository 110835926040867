@media (max-width: 992px) {
  body {
    padding-top: 70px;
    font-size: 15px;
    letter-spacing: 0.07px;
    line-height: 20px;
  }
  header {
    min-height: 70px;
  .block__logo {
      img {
        width: 170px;
      }
    }
  }
  .s {
    .h2, h2 {
      font-size: 25px;
      letter-spacing: 1.25px;
      line-height: 30px;
      &.font-s30 {
        font-size: 25px;
        letter-spacing: 1.25px;
        line-height: 30px;
      }
    }
  }
  .s-top .ring {
    width: 580px;
    height: 580px;
    background-size: cover;
    bottom: -290px;
  }
  .s-top-lp-banken {
    .top__lp-bank {
      min-height: 350px;
    }
  }
  .s-top-lp-banken .ring {
    width: 350px;
    height: 350px;
    background-size: cover;
    top: 180px;
  }
  .block__gadget {
    right: 50%;
    margin-right: -320px;
  }
  .page-title {
    font-size: 25px;
    font-weight: 600;
    font-style: normal;
    letter-spacing: 1.25px;
    line-height: 30px;
  }
  .s-steps {
    .h2, h2 {
      &.font-s30 {
        font-size: 20px;
        letter-spacing: 1px;
        line-height: 30px;
      }
    }
    .carousel-indicators.list__steps {
      padding-left: 25px;
      .item-step:before {
        left: -25px;
      }
      > div > div {
        strong {
          font-size: 18px;
          letter-spacing: 0.9px;
          margin-bottom: 8px;
          &:before {
            left: -41px;
          }
          &::after {
            width: 28px;
          }
        }
        span {
          font-size: 15px;
          letter-spacing: 0.07px;
          line-height: 20px;
        }
      }
    }
  }
  .s-advantages {
    border-top: none;
  }
  .s-prices,
  .s-contacts {
    h2, .h2 {
      font-size: 25px;
      letter-spacing: 1.25px;
      line-height: 30px;
    }
  }
  .s-urs {
    background-color: #efefef;
    .block__urs--info > li {
      display: none;
      padding-top: 0 !important;
      width: 100%;
      span::after {
        display: none;
      }
      &.active {
        display: block;
      }
      .block__urs--info-box {
        width: 100%;
        position: relative;
        left: 0;
        border: none;
        padding: 20px 0 0 0;
      }
    }
    .carousel-rs {
      height: 500px;
      &-outer {
        position: relative;
      }
      .item {
        height: 500px;
      }
    }
    .slider-rs-arrows-mob {
      position: absolute;
      z-index: 10;
      width: 100%;
      left: 0;
      bottom: 0;
      .slider-rs-arrow-mob {
        &-prev,
        &-next {
          bottom: 20px;
          display: block;
          position: absolute;
          width: 41px;
          height: 41px;
          box-shadow: 0 0 10px rgba(0, 0, 0, 0.16);
          background-image: none;
          background-color: #ffffff;
          border: none;
          border-radius: 50%;
          &:before {
            content: "";
            display: block;
            width: 14px;
            height: 24px;
            position: absolute;
            top: 50%;
            left: 50%;
          }
        }
        &-prev {
          left: 20px;
          &:before {
            transform: translate(-50%, -50%);
            background: url('../img/arr-prev-b.svg') 0 0 no-repeat;
          }
        }
        &-next {
          right: 20px;
          &:before {
            transform: translate(-50%, -50%) rotate(180deg);
            background: url('../img/arr-prev-b.svg') 0 0 no-repeat;
          }
        }
      }
    }
  }
  .accordion .card-header .btn {
    &.font-s22 {
      font-size: 18px;
    }
    i {
      width: 19px;
      height: 19px;
      &:before {
        width: 19px;
        height: 2px;
      }
      &:after {
        height: 19px;
        width: 2px;
      }
    }
  }
  .carousel-indicators.list__steps .active .item-step-image {
    background: none;
    img {
      display: block;
    }
  }
  .slider-0 {
    margin-left: 0;
    margin-right: 0;
  }
  .carousel-references {
    .slick-slide {
      & > div {
        min-height: 130px;
        line-height: 130px;
        &:first-child {
          margin-bottom: 40px;
        }
      }
    }
  }
  .carousel-rs {
    position: relative;
    width: 100%;
  }
  // footer
  footer {
    border-top: none;
    .block__footer {
      width: 33%;
    }
  }
  .modal-simple {
    .btn-close {
      top: 20px;
      right: 20px;
      width: 23px;
      height: 23px;
      &:before,
      &:after {
        width: 1px;
        height: 23px;
      }
    }
    .modal-title {
      font-size: 25px;
      font-weight: 600;
      letter-spacing: 1.25px;
      line-height: 30px;
    }
    .font-s25 {
      font-size: 18px;
      line-height: 22px;
    }
    p {
      font-size: 15px;
    }
  }
  .list__articles {
    h3.font-s40 {
      font-size: 25px;
      line-height: 30px;
    }
  }
  .scroll-point {
    &-price {
      top: -70px;
    }
    &-contacts {
      top: -70px;
    }
  }
}