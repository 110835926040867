@media (max-width: 1140px) {
  .block__blue-circle {
    margin-top: 120px;
  }
  .icon-plus-separate {
    left: 50%;
  }

  .block__gadget {
    width: 640px;
    height: 332px;
    >div {
      background-size: cover;
    }
    &--macbook {
      width: 311px;
      height: 195px;
      right: 320px;
    }

    &--imac {
      width: 390px;
      height: 331px;

      .box-video {
        width: 343px;
        height: 206px;
        left: 24px;
        top: 27px;
        .block_video {
          img {
            width: 380px;
          }
        }
        .block_video,
        video {
          width: 380px;
        }
      }
    }

    &--ipad {
      width: 157px;
      height: 193px;
    }
  }

  footer {
    padding-left: 15px;
    padding-right: 15px;
  }
}