@media (max-width: 1380px) {
  // .block__gadget {
  //   width: 640px;
  //   height: 332px;
  //   > div {
  //     background-size: cover;
  //   }
  //   &--macbook {
  //     width: 311px;
  //     height: 195px;
  //     right: 320px;
  //   }
  //   &--imac {
  //     width: 390px;
  //     height: 331px;
  //     .box-video {
  //       width: 343px;
  //       height: 206px;
  //       left: 24px;
  //       top: 27px;
  //       video  {
  //         width: 380px;
  //       }
  //     }
  //   }
  //   &--ipad {
  //     width: 157px;
  //     height: 193px;
  //   }
  // }
  .slider-0 {
    margin-left: 60px;
    margin-right: 60px;
  }
  .slider-0-arrows {
    .slider-0-arrow-prev {
      left: 10px;
    }
    .slider-0-arrow-next {
      right: 10px;

    }
  }
}