.block__contacts {
  &--service {
    .item {
      width: 210px;
      height: 210px;
      padding-top: 45px;
      box-sizing: border-box;
      border-radius: 130px;
      background-color: #ffffff;
      font-size: 17px;
      position: relative;
      .box-icon {
        display: block;
        height: 56px;
        line-height: 56px;
        margin-bottom: 24px;
      }
      strong {
        color: #1e2b57;
      }
      &:before {
        content: "";
        display: block;
        width: 100%;
        height: 100%;
        position: absolute;
        z-index: 0;
        left: 50%;
        top: 50%;
        transform: translate(-50%,-50%);
        border-radius: 50%;
        transition: 0.3s;
        border: 0 solid $color-blue-light-2;
      }
      &:hover {
        &:before {
          transition: 0.3s;
          border-width: 8px;
        }
      }
    }
  }
}

.block__gadget {
  width: 1024px;
  height: 445px;
  position: absolute;
  bottom: -40px;
  right: 100px;
  z-index: 1;
  > div {
    position: absolute;
    background-position: 0 0;
    background-repeat: no-repeat;
  }
  &--macbook {
    background-image: url('../img/macbook.png');
    width: 478px;
    height: 290px;
    bottom: 10px;
    right: 582px;
  }
  &--imac {
    background-image: url('../img/imac.png');
    width: 565px;
    height: 476px;
    bottom: 0;
    right: 150px;
    .box-video {
      width: 480px;
      height: 294px;
      overflow: hidden;
      position: absolute;
      left: 35px;
      top: 38px;
      z-index: 10;
      .block_video {
        img {
          width: 542px;
        }
      }
      .block_video,
      video {
        width: 542px;
        display: block;
        position: relative;
        top: 0;
        left: -20px;
        z-index: 11;
        background: #fff;
      }
    }
  }
  &--ipad {
    background-image: url('../img/ipad.png');
    width: 237px;
    height: 300px;
    right: 0;
    bottom: 0;
    z-index: 12;
  }
}

.block__urs--info {
  position: relative;
  &-box {
    width: 424px;
    padding: 30px 38px 36px;
    background-color: #efefef;
    position: absolute;
    left: 234px;
    top: 0;
    border-left: 1px solid #0073b6;
    line-height: 22px;
    display: none;
    > ul {
      li {
        position: relative;
        padding-left: 13px;
        font-style: normal;
        letter-spacing: 0.08px;
        line-height: 22px;
        margin-bottom: 10px;
        &:before {
          content: "";
          display: block;
          position: absolute;
          left: 1px;
          top: 9px;
          width: 4px;
          height: 1px;
          background: #1e2b57;
        }
        &:last-child {
          margin-bottom: 0;
        }
      }
    }
  }
  > li {
    width: 234px;
    padding-top: 10px;
    padding-bottom: 10px;
    &:first-child {
      padding-top: 0;
    }
    span {
      display: block;
      width: 100%;
      opacity: 0.5;
      color: #0073b6;
      font-size: 22px;
      font-weight: 700;
      font-style: normal;
      letter-spacing: 1.1px;
      line-height: normal;
      text-align: left;
      font-style: normal;
      letter-spacing: normal;
      line-height: normal;
      cursor: pointer;
      position: relative;
      &:after {
        content: "";
        display: block;
        width: 100%;
        height: 1px;
        position: absolute;
        bottom: 0;
        background: #0073b6;
        display: none;
      }
    }
    &.active {
      .block__urs--info-box {
        display: block;
      }
      span {
        opacity: 1;
        &:after {
          display: block;
        }
      }
    }
  }
}

.block__nav {
  &-tabs {
    position: relative;
    z-index: 0;
    li {
      margin-right: 2px;
      a {
        text-decoration: none;
        display: block;
        width: 278px;
        height: 84px;
        padding: 17px 0 0 40px;
        background: #fff;
        color: #1e2b57;
      }
      &.current {
        a {
          background: #1e2b57;
          color: #fff;
        }
      }
    }
    &-item {
      font-size: 15px;
      letter-spacing: 0.07px;
      line-height: 25px;
      h4 {
        font-size: 15px;
        letter-spacing: 0.07px;
        line-height: 25px;
        margin-bottom: 12px;
        color: #2bcaf5;
        a {
          color: #2bcaf5;
          text-decoration: none;
          &:hover {
            text-decoration: underline;
          }
        }
      }
      ul {
        li {
          margin-bottom: 12px;
        }
        a {
          text-decoration: none;
        }
      }
    }
  }
}

.block__scales {
  width: 812px;
  height: 374px;
  margin: 0 auto;
  background: url('../img/scales.png') 0 0 no-repeat;
  position: relative;
  &--left {
    width: 300px;
    height: 232px;
    box-sizing: border-box;
    position: absolute;
    left: 0;
    top: 20px;
    padding: 26px 25px 0 25px;
  }
  &--right {
    width: 265px;
    height: 232px;
    box-sizing: border-box;
    position: absolute;
    right: 0;
    top: 0;
    padding: 26px 25px 0 25px;
    .line-top {
      position: relative;
      padding-top: 7px;
      margin-top: 7px;
      &:before {
        content: "";
        display: block;
        width: 120px;
        height: 1px;
        background: #fff;
        position: absolute;
        left: 0;
        top: 0;
      }
    }
  }
}