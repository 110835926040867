header {
  position: fixed;
  width: 100%;
  min-height: 88px;
  top: 0;
  left: 0;
  z-index: 100;
  .block__logo {
    img {
      width: 234px;
    }
  }
  nav {
    > div > ul > li > a {
      text-decoration: none;
      color: #ffffff;
      font-size: 15px;
      font-weight: 400;
      letter-spacing: 0.75px;
    }
    > div > ul > li {
      position: relative;
      padding: 15px 10px;
      &:last-child {
        padding-right: 0;
      }
      > ul {
        padding: 25px 35px;
        position: absolute;
        top: 100%;
        left: 50%;
        transform: translateX(-50%);
        background-color: #0073b6;
        display: none;
        > li {
          margin-bottom: 20px;
          > a {
            white-space: nowrap;
            font-size: 15px;
            font-weight: 400;
            font-style: normal;
            letter-spacing: 0.07px;
            text-decoration: none;
            color: #fff;
          }
          &:last-child {
            margin-bottom: 0;
          }
          &.current, &:hover {
            > a {
              color: #2bcaf5;
            }
          }
        }
      }
      &:hover {
        > ul {
          display: block;
        }
      }
    }
  }
}

.link-open-nav {
  position: absolute;
  display: block;
  width: 23px;
  height: 50px;
  right: 0;
  top: 0;
  background: none;
  border: none;
  span {
    display: block;
    width: 23px;
    height: 16px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%,-50%);
    i {
      width: 23px;
      position: absolute;
      height: 1px;
      background: #fff;
      left: 0;
      &:nth-child(1) {
        top: 0;
      }
      &:nth-child(2) {
        top: 7.5px;
      }
      &:nth-child(3) {
        top: 15px;
      }
    }
  }
}