footer {
  border-top: 1px solid #e5e5e5;
  background: #fff;
  font-size: 14px;
  font-weight: 400;
  font-style: normal;
  letter-spacing: 0.14px;
  line-height: 25px;
  color: #1e2b57;
  a {
    color: #1e2b57;
    text-decoration: underline;
    &:hover {
      text-decoration: none;
    }
  }
}