@import "vendors/bootstrap/bootstrap";
@import "vendors/slick/slick";

@import "main/fonts";
@import "main/global";

@import "layouts/header";
@import "layouts/main";
@import "layouts/pages";
@import "layouts/footer";

@import "components/forms";
@import "components/blocks";
@import "components/carousel";
@import "components/accordions";
@import "components/lists";
@import "components/modal";


@import "main/responsive";