.modal {
  &-nav {
    .btn-close {
      width: 43px;
      height: 43px;
      top: 10px;
      right: 10px;
      background: none;
      border: none;
      position: absolute;
      z-index: 10;
      svg {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%,-50%);
      }
    }
    .modal-body {
      padding-top: 80px;
      background-color: #0073b6;
      nav {
        li {
          margin-bottom: 30px;
        }
        a {
          color: #fff;
          text-decoration: none;
          font-size: 20px;
          line-height: 20px;
          letter-spacing: 1px;
        }
      }
    }
    .block__query {
      text-align: center;
      width: 288px;
      height: 289px;
      position: relative;
      margin: 0 auto -144px;
      &:before {
        content: "";
        display: block;
        position: absolute;
        width: 100%;
        height: 100%;
        background: url('../img/ring_small.png');
        z-index: 0;
        top: 0;
      }
      a {
        top: 50%;
        left: 50%;
        transform: translate(-50%,-50%);
        position: absolute;
        z-index: 3;
        width: 155px;
        height: 155px;
        border-radius: 50%;
        padding-top: 34px;
        box-sizing: border-box;
        background: #fff;
        text-decoration: none;
        span {
          font-weight: 700;
          color: #1e2b57;
        }
      }
    }
  }
  &-full {
    .modal-dialog {
      max-width: 100%;
      width: 100%;
      height: 100vh;
      margin: 0;
      background: #fff;
    }
  }
  &-simple {
    .btn-close {
      background: none;
      border: none;
      width: 38px;
      height: 38px;
      position: absolute;
      top: 70px;
      right: 70px;
      z-index: 10;
      &:before,
      &:after {
        content: "";
        display: block;
        width: 3px;
        height: 36px;
        position: absolute;
        left: 50%;
        top: 50%;
        background: #bbb;
      }
      &::before {
        transform: translate(-50%,-50%) rotate(45deg);
      }
      &::after {
        transform: translate(-50%,-50%) rotate(-45deg);
      }
    }
    .modal-dialog {

    }
    .modal-content {
      border-radius: 0;
    }
    .modal-title {
      font-size: 50px;
      font-weight: 600;
      letter-spacing: 2.5px;
    }
  }
}