.carousel-indicators {
  &.list__steps {
    position: relative;
    display: block;
    margin: 0;
    padding-left: 50px;
    .item-step {
      position: relative;
      &:before {
        content: "";
        display: block;
        width: 1px;
        height: 100%;
        background: $color-blue;
        position: absolute;
        left: -50px;
        top: 20px;
      }
      &:last-child {
        &:before {
          display: none;
        }
      }
    }
    > div {
      cursor: pointer;
      > div {
        padding: 10px;
        strong {
          color: #0073b6;
          font-size: 20px;
          font-weight: 600;
          font-style: normal;
          letter-spacing: 1px;
          margin-bottom: 10px;
          display: block;
          position: relative;
          &:before, &:after {
            content: "";
            display: block;
            position: absolute;
          }
          &:before {
            left: -66px;
            top: 4px;
            width: 13px;
            height: 13px;
            border-radius: 50%;
            background-color: #1e2b57;
            z-index: 1;
          }
          &:after {
            z-index: 0;
            width: 50px;
            height: 1px;
            background-color: #1e2b57;
            top: 10px;
            left: -10px;
            transform: translateX(-100%);
          }
        }
        span {
          color: #1e2b57;
          font-size: 16px;
          font-weight: 400;
          font-style: normal;
          letter-spacing: 0.08px;
          line-height: 22px;
        }
      }
      &.active {
        > div {
          background-color: rgba(43, 202, 245, 0.2);
          strong {
            color: #2bcaf5;
            &:before {
              background-color: #2bcaf5;
            }
            &:after {
              background-color: #707070;
            }
          }
        }
      }
    }
    .item-step-image {
      padding: 10px 0 5px 10px;
      img {
        display: none;
      }
    }
  }
}
.list__articles {
  &--item {
    position: relative;
    .point-scroll {
      position: absolute;
      left: 0;
      top: -110px;
    }
  }
  a {
    text-decoration: none;
    color: #0073b6;
  }
}
.list__advantages {
  .item {
    width: 230px;
    height: 230px;
    margin-left: 5px;
    margin-right: 5px;
    margin-bottom: 10px;
    border-radius: 50%;
    background-color: #ffffff;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: 17px;
    letter-spacing: 0.09px;
    line-height: 25px;
  }
}
.list-marker {
  li {
    margin-bottom: 18px;
  }
  &-check {
    li {
      position: relative;
      padding-left: 26px;
      &:before {
        content: "";
        display: block;
        position: absolute;
        left: 0;
        top: 4px;
        width: 16px;
        height: 14px;
        background: url('../img/marker-check.svg') 0 0 no-repeat;
      }
    }
  }
}
.list__articles {
  &.img-h-380 {
    .item-img {
      min-height: 380px;
      line-height: 380px;
    }
  }
  &.img-h-360 {
    .item-img {
      min-height: 360px;
      line-height: 360px;
    }
  }
  &.img-h-320 {
    .item-img {
      min-height: 320px;
      line-height: 250px;
    }
  }
  &.img-h-260 {
    .item-img {
      min-height: 260px;
      line-height: 260px;
    }
  }
  &.img-h-250 {
    .item-img {
      min-height: 250px;
      line-height: 250px;
    }
  }
  &.img-h-140 {
    .item-img {
      min-height: 140px;
      line-height: 140px;
    }
  }
  p {
    margin-bottom: 0;
  }
}


.list__prices {
  margin-left: -6px;
  margin-right: -6px;
  color: #1e2b57;
  font-size: 15px;
  font-weight: 400;
  font-style: normal;
  letter-spacing: 0.07px;
  line-height: 22px;

  .item {
    margin-left: 6px;
    margin-right: 6px;
    h3 {
      font-weight: 800;
    }
    .desc {
      min-height: 75px;
    }
    .price-num {
      font-size: 16px;
      letter-spacing: 0.08px;
      line-height: 25px;
      padding-bottom: 20px;
      margin-bottom: 20px;
      border-bottom: 2px solid #e5e5e5;
      strong {
        font-size: 25px;
        font-style: normal;
        letter-spacing: 1.25px;
        font-weight: 900;
      }
    }
    ul {
      li {
        background: url('../img/marker-check.svg') 0 2px no-repeat;
        padding-left: 24px;
        margin-bottom: 10px;
      }
    }
  }
}