* {
  margin: 0;
  padding: 0;
}
html {
  scroll-behavior: smooth;
  font-smooth: always;
  font-stretch: normal;
  font-style: normal;
  line-height: initial;
}
body {
  font-family: $font, Arial, Helvetica, sans-serif;
  font-optical-sizing: auto;
  font-weight: 400;
  font-style: normal;
  background-color: #ffffff;
  background-repeat: no-repeat;
  color: #1e2b57;
  font-size: 16px;
  letter-spacing: 0.08px;
  line-height: 25px;
  position: relative;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding-top: 88px;
}
strong {
  font-weight: 700;
}
h1, .h1, h2, .h2, h3, .h3, h4, .h4, h5, .h5, h6, .h6{
  font-weight: 600;
}

ul, ol {
  margin: 0;
  padding: 0;
  li {
    list-style: none;
  }
}
a {
  text-decoration: underline;
  &:hover {
    text-decoration: none;
  }
}
article {
  a, a:not(.btn) {
    text-decoration: none;
    color: #0073b6;
    &:hover {
      text-decoration: underline;
    }
  }
}
p {
  margin-bottom: 24px;
}
.bg-grey {
  background-color: #efefef;
}

.bg-blue,
.bg-blue-light {
  color: #fff;
  a:not(.btn) {
    color: #fff;
    &:hover {
      color: #2bcaf5;
    }
  }
}

.bg-blue {
  background: $color-blue;
}

.bg-blue-light {
  background: $color-blue-light;
}
.bg-grey {
  background-color: #efefef;
}
.bg-grey-full {
  &:after {
    content: "";
    display: block;
    background-color: #efefef;
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    z-index: 0;
  }
  > div {
    position: relative;
    z-index: 1;
  }
  &-right {
    &:after {
      right: 0;
      transform: translateX(100%);
    }
  }
}
.text-blue-light {
  color: $color-blue-light;
}
.text-blue-light-2 {
  color: #2bcaf5;
}

.line-blue-light {
  height: 1px;
  background-color: #2bcaf5;
}

.font-bold {
  font-weight: 700;
}

.font-medium {
  font-weight: 600;
}

.font-s50 {
  font-size: 50px;
  letter-spacing: 2.5px;
  line-height: 50px;
}
.font-s40 {
  font-size: 40px;
  letter-spacing: 2px;
  line-height: 50px;
}
.font-s30 {
  font-size: 30px;
  font-weight: 600;
  font-style: normal;
  letter-spacing: 1.5px;
}

.font-s25 {
  font-size: 25px;
  letter-spacing: 1.25px;
  line-height: normal;
}

.font-s22 {
  font-size: 22px;
  font-weight: 600;
  font-style: normal;
  letter-spacing: 1.1px;
  line-height: normal;
}
.font-s20 {
  font-size: 20px;
  letter-spacing: 1px;
  line-height: 35px
}
.font-s18 {
  font-size: 18px;
  letter-spacing: 0.9px;
  line-height: 25px;
}
.font-s17 {
  font-size: 17px;
  letter-spacing: 0.85px;
  line-height: 25px;
}

.text-white {
  &.text-shadow {
    text-shadow: 0 0 20px #000000;
  }
}

.img-fullbg {
  background-repeat: no-repeat;
  background-position: left center;
  // fixed;
  background-size: cover;
  &-bgp-right{
    background-position: right center;
  }
}

.label-blue {
  font-size: 20px;
  line-height: 20px;
  font-weight: 700;
  font-style: normal;
  letter-spacing: 1px;
  display: inline-block;
  vertical-align: middle;
  padding: 10px 20px 7px;
  text-transform: uppercase;
  background-color: #2bcaf5;
  border-radius: 19px;
  color: #fff;
}

.block__video {
  position: relative;
  padding-bottom: calc(var(--aspect-ratio, .5625) * 100%);
  height: 0;
  width: 100%;
}

.block__video iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

iframe {
  border: 0px;
}

.modal-backdrop {
  &.show {
    opacity: 0.8;
  }
}