.scroll-point {
  position: relative;
  opacity: 0;
  z-index: -1;
  left: 0;
  &-contacts {
    top: 0;
  }
  &-price {
    top: -80px;
  }
}