.accordion {
  .card {
    border: none;
  }
  .card-header {
    border-top: 0;
    padding: 0;
    background: none;
    border-top: none;
    border-bottom: 1px solid #ccc;
    margin-bottom: 0 !important;
    .btn {
      text-align: left;
      padding-top: 15px;
      padding-bottom: 15px;
      padding-left: 0;
      padding-right: 0;
      display: block;
      width: 100%;
      color: #0073b6;
      position: relative;
      i {
        display: block;
        position: absolute;
        right: 0;
        top: 15px;
        width: 26px;
        height: 26px;
        &:before,
        &:after {
          content: "";
          display: block;
          position: absolute;
          background: #ccc;
          top: 50%;
          left: 50%;
          transform: translate(-50%,-50%);
        }
        &:before {
          width: 26px;
          height: 3px;
        }
        &:after {
          width: 3px;
          height: 26px;
        }
      }
      &:not(.collapsed) {
        i {
          &:after {
            opacity: 0;
          }
        }
      }
      &:hover,
      &:focus {
        text-decoration: none;
      }
      &:hover {
        border-color: #fff;
        color: $color-blue-light-2;
      }

    }
  }
  .card-body {
    padding-left: 0;
    padding-right: 0;
    article {
      p {
        &:last-child {
          margin-bottom: 0;
        }
      }
    }
  }
}