@media (max-width: 768px) {
  .s-top {
    .ring {
      width: 350px;
      height: 350px;
      bottom: -350px;
      background-image: url('../img/ring-top-mob.png');
    }
    p {
      font-size: 15px;
      letter-spacing: 0.15px;
      line-height: 20px;
    }
  }
  .s-top-lp-banken {
    .top__lp-bank {
      min-height: 350px;
    }
  }

  .s-top-lp-banken .ring {
    width: 350px;
    height: 350px;
    background-size: cover;
    top: 175px;
    background-image: url('../img/ring-top-2-mob.png');
  }
  .block__gadget {
    width: 350px;
    margin-right: -175px;
    bottom: -75px;
    // border: 1px solid #000; // marker
    .block__gadget--imac {
      right: 0;
      width: 350px;
      background-size: 350px auto;
      .box-video {
        width: 302px;
        height: 180px;
        .block_video {
          img {
            width: 337px;
          }
        }
        .block_video,
        video {
          width: 337px;
        }
      }
    }
    .block__gadget--macbook {
      right: 90px;
      bottom: -150px;
      width: 270px;
      background-size: 270px auto;
    }
    .block__gadget--ipad {
      width: 120px;
      background-size: 120px auto;
      bottom: -68px;
    }
  }
  .s-urs {
    .carousel-rs {
      height: 380px;
      .item {
        height: 380px;
      }
    }
  }
    .bg-md-none {
      background: none !important;
    }
  .list__articles {
    .item-img {
      min-height: auto !important;
      line-height: normal !important;
    }
    [class*=col] {
      padding-top: 30px;
      padding-bottom: 30px;
      &:nth-child(even) {
        background: #fff;
      }
      &:nth-child(odd) {
        background-color: #efefef;
      }
    }
    &-revers {
      [class*=col] {
        &:nth-child(even) {
          background-color: #efefef;
        }
        &:nth-child(odd) {
          background: #fff;
        }
      }
    }
  }
  .s-articles-branchen {
    background-color: #efefef;
  }
  .list__articles-branchen {
    background-color: #efefef;
    [class*=col] {
      &:nth-child(even),
      &:nth-child(odd) {
        background: none !important;
        padding-top: 0;
        padding-bottom: 30px;
      }
    }
  }
  .list__advantages-circle {
    .slick-slide {
      display: flex;
      justify-content: center;
    }
  }

  // footer
  footer {

    .block__footer {
      width: 100%;
      &-1 {
        width: 60%;
      }
      &-3 {
        width: 40%;
      }
    }
  }
}