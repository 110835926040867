.btn {
  font-size: 15px;
  line-height: normal;
  font-weight: 600;
  font-style: normal;
  letter-spacing: 0.75px;
  border-radius: 21px;
  text-decoration: none;
  padding: 10px 18px;
  display: inline-block;
  &:focus {
    box-shadow: none;
  }
  &:hover {
    text-decoration: none;
  }
  &.font-s18 {
    border-radius: 25px;
    font-size: 18px;
    padding: 17px 38px 14px;
  }
  &.font-s22 {
    font-size: 22px;
  }
  &-blue {
    background: $color-blue-light;
    color: #fff;
  }
  &-white {
    background: #fff;
    color: #1e2b57;
  }
  &-white, &-blue {
    &:hover {
      background: #2bcaf5;
      color: #fff;
    }
  }
}

form {
  label {
    display: block;
    font-size: 12px;
    font-weight: 400;
    font-style: normal;
    letter-spacing: 0.12px;
    line-height: 22px;
    position: relative;
    margin-bottom: -32px;
    margin-left: 12px;
  }
  .form-control {
    border-radius: 0;
    height: 64px;
    padding-top: 30px;
    background-color: #efefef;
    border: 2px solid #efefef;
    border: none;
    font-size: 16px;
    font-weight: 600;
    font-style: normal;
    letter-spacing: 0.16px;
    color: #0073b6;
    &::placeholder {
      color: #0073b6;
      // background-color: rgba(0, 115, 182, 0.1);
    }
    &:focus {
      color: #0073b6;
      border: 1px solid rgba(0, 115, 182, 0.1);
      box-shadow: none;
      background-color: rgba(0, 115, 182, 0.1);
    }
  }
  textarea {
    resize: none;
    &.form-control {
      padding-top: 36px;
      height: 181px;
    }
  }
}
.invalid-feedback {
  font-size: 12px;
  margin-bottom: 0;
}
.was-validated .form-control:valid,
.form-control.is-valid,
.was-validated .form-control:invalid,
.form-control.is-invalid {
  background-image: none;
}
.was-validated .form-control:invalid,
.form-control.is-invalid {
  border: 2px solid #f80a1c;
}