@media (max-width: 1280px) {
  .block__gadget {
    width: 640px;
    height: 332px;
    >div {
      background-size: cover;
    }
    &--macbook {
      width: 311px;
      height: 195px;
      right: 420px;
    }
    &--imac {
      width: 500px;
      height: 421px;
      .box-video {
        width: 436px;
          height: 262px;
          left: 32px;
          top: 33px;
        .block_video {
          img {
            width: 476px;
          }
        }
        .block_video,
        video {
          width: 476px;
        }
      }
    }
    &--ipad {
      width: 157px;
      height: 193px;
    }
  }

  .modal-simple {
    .btn-close {
      top: 30px;
      right: 30px;
    }
  }
}
