@media (max-width: 568px) {
  .no-mob-padding {
    margin-left: -19px;
    margin-right: -19px;
    width: calc(100% + 38px);
    display: block;
  }
  .s-urs {
    .carousel-rs {
      height: 320px;

      .item {
        height: 320px;
      }
    }
  }
  .modal {
    .modal-dialog {
      margin: 0;
      form {
        .form-group {
          margin-bottom: 8px !important;
        }
        .btn {
          width: 100%;
        }
      }
    }
  }
  .list__articles-branchen {
    img {
      width: 100%;
    }
  }
  .list__prices .item .desc {
    min-height: auto;
  }
}